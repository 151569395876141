<template>
    <st-section
        :header="$t('REAL_ESTATE.LIST_HEADER')"
        customClass="list-section"
        id="real-estate-target"
        :validate="!viewMode"
        :wasValidated="wasValidated"
        :validation="validationStatus"
    >
        <template #body>
            <carousel-3d
                :controls-visible="true"
                :width="400"
                :height="220"
                :space="300"
                :count="itemsComputed.length"
                dispay="3"
                ref="realEstateCarousel"
            >
                <slide v-for="(item, i) in itemsComputed" :index="i" :key="i">
                    <div class="carousel-card real-estate-card">
                        <real-estate-item-card
                            v-if="!item.isPlaceholder"
                            @edit="onEdit"
                            @delete="onDelete"
                            @viewDetails="onViewDetails"
                            :item="item"
                            :index="i"
                            :viewMode="viewMode"
                            :canViewDetails="canViewDetails"
                        ></real-estate-item-card>
                        <div class="card-user-placeholder" v-else>
                            <i class="fas fa-home"></i>
                        </div>
                    </div>
                </slide>
            </carousel-3d>
            <div class="d-flex list-footer">
                <div class="col-9 d-flex align-items-center px-0">
                    <span>{{ total }}</span>
                </div>
                <div
                    class="col-3 d-flex justify-content-end px-0"
                    v-if="!viewMode"
                >
                    <st-button :callback="openRealEstateModal" variant="link">
                        <span class="d-flex align-items-center">
                            <i class="fas fa-plus"></i>
                            {{ $t("REAL_ESTATE.FORM.ADD_BTN") }}
                        </span>
                    </st-button>
                </div>
            </div>

            <real-estate-modal
                ref="realEstateModal"
                :item="selectedEntity"
                :cadastralNumber="cadastralNumber"
                @processRealEstate="processRealEstate"
                @cancel="cancel"
            ></real-estate-modal>

            <real-estate-view-modal
                ref="realEstateViewModal"
                :items="items"
                :index="selectedEntityIndex"
            ></real-estate-view-modal>

            <real-estate-details-modal
                v-if="canViewDetails"
                ref="realEstateDetailsModal"
                :item="selectedEntity"
            ></real-estate-details-modal>
        </template>
    </st-section>
</template>

<script>
import Vue from "vue";
import { ApplicationsPermissions } from "@/modules/applications/applications-permissions";
import { mapActions, mapGetters } from "vuex";
import { Carousel3d, Slide } from "vue-carousel-3d";
import { RealEstateModel } from "@/modules/applications/models/real-estate-model";
import RealEstateModal from "@/modules/applications/components/real-estate/RealEstateModal";
import RealEstateViewModal from "@/modules/applications/components/real-estate/RealEstateViewModal";
import RealEstateDetailsModal from "@/modules/applications/components/real-estate/RealEstateDetailsModal";
import RealEstateItemCard from "@/modules/applications/components/real-estate/RealEstateItemCard";

const { fields } = RealEstateModel;

export default {
    name: "RealEstateList",
    components: {
        RealEstateModal,
        RealEstateViewModal,
        RealEstateItemCard,
        RealEstateDetailsModal,
        Carousel3d,
        Slide,
    },
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        applicationType: {
            type: String,
        },
        wasValidated: {
            type: Boolean,
            default: false,
        },
        setDefault: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            items: [],
            selectedEntity: null,
            selectedEntityIndex: null,
            cadastralNumber: [],
        };
    },
    watch: {
        record(newValue) {
            if (newValue?.is_internal) {
                this.items = newValue?.form?.real_estate_target ?? newValue?.form_staff?.real_estate_target ?? [];
            } else {
                if (this.canViewDetails) {
                    this.items = newValue?.form_staff?.real_estate_target ?? [];
                } else {
                    this.items = newValue?.form?.real_estate_target ?? [];
                }
            }

            this.doSetRealEstates(this.items);
            this.$emit("changeList", this.items, "real_estate_target", true);
        },
    },
    computed: {
        ...mapGetters({
            moduleName: "shared/moduleName",
            currentUser: "auth/currentUser"
        }),
        hasPermissionToReadStaff() {
            return new ApplicationsPermissions(this.currentUser).readStaff;
        },
        canViewDetails() {
            return this.hasPermissionToReadStaff && !this.hideDetails
        },
        record() {
            const currentState = this.moduleName ?? 'applications';
            return this.$store.state[currentState]?.form?.record;
        },
        availableUatIds() {
            const currentState = this.moduleName ?? 'applications';
            return this.$store.state[currentState]?.form?.availableUatIds;
        },
        fields() {
            fields.cadastral_number.tdClass = "d-flex col-md-2 align-items-center";
            fields.address.tdClass = "d-flex col-md-3 align-items-center";
            fields.real_estate_type.tdClass = "d-flex col-md-5 align-items-center";
            return [
                fields.cadastral_number,
                fields.address,
                fields.real_estate_type,
            ];
        },
        total() {
            return this.items.length === 1
                ? this.$t("REAL_ESTATE.SINGLE", { total: 1 })
                : this.$t("REAL_ESTATE.MULTIPLE", { total: this.items.length });
        },
        itemsComputed() {
            const countItems = this.items?.length ?? 0;
            if (!countItems >= 3) return [...this.items];
            const previewItems = 3;
            let visibleItems = [];
            if (countItems > 0) {
                visibleItems = [...this.items];
            }
            const placeholderItems = previewItems - countItems;
            for (let i = 0; i < placeholderItems; i += 1) {
                visibleItems.push({
                    isPlaceholder: true,
                });
            }

            return visibleItems;
        },
        validationStatus() {
            return this.items?.length ? "success" : "error";
        },
    },
    methods: {
        ...mapActions({
            // doSetRealEstates: "applications/form/doSetRealEstates",
        }),
        doSetRealEstates(payload) {
            const currentModule = this.moduleName ?? 'applications';
            return this.$store.dispatch(`${currentModule}/form/doSetRealEstates`, payload);
        },
        openRealEstateModal() {
            this.selectedEntity = null;
            this.selectedEntityIndex = null;
            this.$refs.realEstateModal.show();
        },
        async processRealEstate(data) {
            const isEdit = Object.keys(data).some((el) => el === "index");
            if (isEdit) {
                Vue.set(this.items, data.index, data.realEstateDetails);
            } else {
                this.items.push({
                    ...data.realEstateDetails,
                    index: this.items.length,
                });
                this.goToSlide(this.items.length - 1);
            }
            this.$emit("changeList", this.items, "real_estate_target", true);

            this.selectedEntity = null;
            this.selectedEntityIndex = null;
            this.refreshCadastralNumber();
            this.doSetRealEstates(this.items);
        },
        onEdit({ item, index }) {
            this.selectedEntity = item;
            this.selectedEntity.index = index;
            this.selectedIndex = index;
            this.$refs.realEstateModal.show();
        },
        onDelete({ index }) {
            this.items.splice(index, 1);
            this.refreshCadastralNumber();
            this.goToSlide(0);
            this.doSetRealEstates(this.items);
        },
        onViewDetails({ item, index }) {
            this.selectedEntity = item;
            this.selectedEntity.index = index;
            this.selectedIndex = index;
            this.$refs.realEstateDetailsModal.show();
        },
        cancel() {
            this.selectedEntity = null;
            this.selectedEntityIndex = null;
        },
        refreshCadastralNumber() {
            this.cadastralNumber = this.items.map((el, index) => {
                return {
                    cadastral_number: el.cadastral_number,
                    territorial_administrative_unit: el.territorial_administrative_unit,
                    index
                };
            });
        },
        goToSlide(index) {
            this.$refs.realEstateCarousel.goSlide(index);
        },
        computeItems() {
            let item;
            if (this.record?.is_internal) {
                item = this.record?.form?.real_estate_target ?? this.record?.form_staff?.real_estate_target ?? [];
            } else {
                if (this.canViewDetails) {
                    item = this.record?.form_staff?.real_estate_target ?? [];
                } else {
                    item = this.record?.form?.real_estate_target ?? [];
                }
            }

            const realEstateSet = item.length > 0;

            if (this.currentUser.user_type_name === 'staff') {
                return item;
            }

            const items = realEstateSet ? item.filter(target => this.availableUatIds.includes(target.territorial_administrative_unit_id)) : [];

            return items;
        }
    },
    created() {
        this.items = [];

        if (this.$route.params.id || this.setDefault) {
            this.items = this.computeItems();

            const realEstateSet = this.items?.length > 0;

            this.cadastralNumber = realEstateSet ? this.items.map((el, index) => {
                return {
                    cadastral_number: el.cadastral_number,
                    index
                };
            }) : [];
        }
    },
};
</script>
